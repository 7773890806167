// ** Initial State
const initialState = {
    selectedVideo: {},
    isLoadingVideo: true,
    coachesList: [],
    sessionList: [],
    sessionDashboardList: [],
    engagementsList: [],
    isLoadingHomePage: true,
    data: [],
    trainingData: [],
    total: 1,
    trainingTotal: 0,
    params: {},
    selectedRequest: {},
    isLoadingEngagements: true,
    isLoadingTrainings: true,
    isLoadingVideoList: true,
    useFirstName: null,
    organizationLogo: null,
    videoList: [],
    videoScrollList: [],
    blockScrolling: false,
    totalVideos: null,
    videoViewed: null,
    totalSessions: 0,
    totalRegistered: 0,
    totalDurationSec: 0,
    nextSession: {},
    reflectionsList: []
}

const homepageCoachee = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ENGAGEMENT_REQUESTS_TABLE_LOADING_STATUS':
            return { ...state, isLoadingEngagements: action.data }
        case 'SET_TRAINING_REQUESTS_TABLE_LOADING_STATUS':
            return { ...state, isLoadingTrainings: action.data }
        case 'REQUESTS_GET_ENGAGEMENT_REQUESTS':
            return {
                ...state,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                selectedRequest: {},
                isLoadingEngagements: false
            }
        case 'REQUESTS_GET_ENGAGEMENT_TRAININGS':
            return {
                ...state,
                trainingData: action.data,
                trainingTotal: action.totalPages,
                params: action.params,
                selectedRequest: {},
                isLoadingTrainings: false
            }
        case 'GET_HOMEPAGE':
            return {
                ...state,
                sessionList: action?.sessionList ?? [],
                coachesList: action?.coachesList ?? [],
                engagementsList: action?.engagementsList ?? [],
                isLoadingHomePage: false,
                organizationLogo: action?.organizationLogo,
                userFirstName: action?.userFirstName
            }
        case 'GET_MEDIADAILY':
            return { ...state, selectedVideo: action?.selectedVideo ?? null, isLoadingVideo: false }
        case 'GET_VIDEOLIST':
            return { ...state, videoList: action?.videoList, isLoadingVideoList: false }
        case 'GET_VIDEO':
            return { ...state, videoViewed: action?.videoViewed ?? {} }
        case 'GET_VIDEO_SCROLL_LIST':
            return {
                ...state,
                videoScrollList: [...(action?.init ? [] : state.videoScrollList), ...action?.videoScrollList],
                blockScrolling: action?.blockScrolling,
                totalVideos: action?.totalRecords
            }
        case 'GET_HOMEPAGE_SESSIONS':
            return {
                ...state,
                sessionDashboardList: action.sessionList,
                totalSessions: action.totalPages,
                params: action.params
            }
        case 'GET_LIVE_EVENTS':
            return { ...state, totalRegistered: action?.totalRegistered ?? 0 }
        case 'GET_VIDEO_MINUTES':
            return { ...state, totalDurationSec: action?.totalDurationSec ?? 0 }
        case 'GET_SESSION_WIDGET':
            return { ...state, nextSession: action?.nextSession }
        case 'GET_HOMEPAGE_REFLECTIONS':
            return { ...state, reflectionsList: action?.reflectionsList }
        default:
            return { ...state }
    }
}
export default homepageCoachee
