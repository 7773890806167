// ** Initial State
const initialState = {
  userDetail: {},
  selectedUser: {},
  params: {}
}

const users = (state = initialState, action) => {
  const test = { ...state, orgDetails: action.data }
  switch (action.type) {
    case 'USER_GET_PROFILE_DATA':
      return { ...state, userDetail: action.userDetails }
    case 'LAWFIRM_GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'USER_CLEAR_SELECTED_USER':
      return { ...state, userDetail: {}, selectedUser: {} }
    case 'USER_UPDATE_PROFILE_PICTURE_REF':
      state.userDetail.avatar = action.newProfilePictureRefS3
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
