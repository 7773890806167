// ** Initial State
const initialState = {
  balanceData: {},
  selectedUser: {},
  currentEngagements: 0,
  coachingEngagementsScheduled: 0,
  totalCoachingHours: 0,
  coacheeLoginsCount: 0,
  engagementsList: [],
  engagementData: [],
  trainingData: [],
  coacheesData: [],
  engagementTotal: 0,
  trainingTotal: 0,
  coacheesTotal: 0,
  params: {},
  selectedRequest: {},
  isLoadingEngagements: true,
  isLoadingTrainings: true,
  isLoadingCoachees: true
}

const homeReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'LAWFIRM_GET_DASHBOARD_DATA':
      return {
        ...state,
        balanceData: action.balanceData,
        currentEngagements: action.currentEngagements,
        coachingEngagementsScheduled: action.coachingEngagementsScheduled,
        totalCoachingHours: action.totalCoachingHours,
        coacheeLoginsCount: action.coacheeLoginsCount
      }
    case 'SET_ENGAGEMENT_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingEngagements: action.data }
    case 'SET_TRAINING_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTrainings: action.data }
    case 'SET_LAWFIRM_USERS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingCoachees: action.data }
    case 'GET_LAWFIRM_ENGAGEMENT_REQUESTS':
      return {
        ...state,
        engagementData: action.data,
        engagementTotal: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingEngagements: false
      }
    case 'GET_LAWFIRM_ENGAGEMENT_TRAININGS':
      return {
        ...state,
        trainingData: action.data,
        trainingTotal: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingTrainings: false
      }
    case 'GET_LAWFIRM_USERS_DATA':
      return {
        ...state,
        coacheesData: action.data,
        coacheesTotal: action.totalPages,
        params: action.params,
        isLoadingCoachees: false
      }
    case 'GET_LAWFIRM_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'CLEAR_LAWFIRM_SELECTED_USER':
      return { ...state, selectedUser: {} }
    default:
      return { ...state }
  }
}
export default homeReducer
